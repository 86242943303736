import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Container, Stack } from "@mui/material";
import { Button } from "react-scroll";
import { useTranslation } from "react-i18next";
import { RequestNovel } from "./RequestNovel";
import { useAuth } from "hooks";
import i18next from "i18next";
import axios from "axios";

export default function CartDialog({ open, setOpen, novel }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const [showRequestAnimations, setShowRequestAnimations] =React.useState(false);
  const [showRequest, setShowRequest] = React.useState(false);
  const handleRequestNowClick = () => {
    setOpen(false)
    setShowRequestAnimations(true);
    setTimeout(() => {
      setShowRequest(true);
      setShowRequestAnimations(false);
    }, 300);
  };
  const handleClose = () => {
    setOpen(false);
  };
  let timeout;
  React.useEffect(() => {
    clearTimeout(timeout);
    if (open) {
      timeout = setTimeout(() => {
        setOpen(false);
      }, 4000);
    }
  }, [open, setOpen]);
  const handelRequestHide = () => {
    setShowRequestAnimations(true);
    setTimeout(() => {
      setShowRequest(false);
      setShowRequestAnimations(false);
    }, 300);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        sx={{
          ".MuiPaper-root": {
            bgcolor: "black",
            border: "4px solid #f9aa10",
            borderRadius: "20px",
          },
        }}
      >
        <DialogContent>
          <Stack
            gap={3}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              p: "10px",
            }}
          >
            {novel.images.length > 0 && (
              <img
                src={`${process.env.REACT_APP_LARAVEL_BASE_URL}/${novel.images[0]}`}
                style={{ height: "150px", width: "150px", objectFit: "cover" }}
              />
            )}{" "}
            <Container
              maxWidth="sm"
              sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <p style={{ fontWeight: "bold", color: "white" }}>
                {novel.title}
              </p>
              <p
                dangerouslySetInnerHTML={{ __html: novel.description }}
                style={{ color: "white", fontSize: "10px" }}
              ></p>
              <Stack
                direction="row"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {novel.price_after_discount === null ? (
                  <p style={{ fontWeight: "bold", color: "#f9aa10" }}>
                    {t("Price :")} {novel.price} SAR
                  </p>
                ) : (
                  <>
                    <p style={{ fontWeight: "bold", color: "#f9aa10", textDecoration:'line-through' }}>
                      {t("Price :")} {novel.price} SAR
                    </p>
                    <p style={{ fontWeight: "bold", color: "#f9aa10" }}>
                      {t("Price After Discount :")} {novel.price_after_discount} SAR
                    </p>
                  </>
                )}
                <p style={{ fontWeight: "bold", color: "#f9aa10" }}>
                  {t("Author :")} {novel.author_name}
                </p>
              </Stack>
            </Container>
            <Button
              className="request"
              onClick={() => {
                handleRequestNowClick();
              }}
            >
              {t("View Cart")}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      {showRequest && (
        <RequestNovel
          HideRequest={handelRequestHide}
          Animations={showRequestAnimations}
          setShowRequest={setShowRequest}
        />
      )}
    </React.Fragment>
  );
}
