import {
  Layout, Home, Category, Novel, RequestNovel,
  Dashboard, NovelsDahboard, Wishlist, Support,
  Profile, Privacy, ResetPassword
} from "components";
import { Routes, Route } from "react-router-dom";

const novels = [
  { id: 1, name: "novel1" },
  { id: 5, name: "novel2" },
  { id: 6, name: "novel3" },
];

export const Router = () => (
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/home" element={<Home />} />
      <Route path="/register" element={<Home />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/category/:id" element={<Category />} />
      <Route path="/novel/:id" element={<Novel />} />
      <Route path="/request">
        {novels.map((novel) => (
          <Route
            key={novel.id}
            path={`${novel.id}`}
            element={<RequestNovel NovelId={novel.id} />}
          />
        ))}
      </Route>
    </Route>
    <Route path="/dashboard" element={<Dashboard />}>
      <Route index element={<NovelsDahboard />} />
      <Route path="novels" element={<NovelsDahboard />} />
      <Route path="wishlist" element={<Wishlist />} />
      <Route path="support" element={<Support />} />
      <Route path="profile" element={<Profile />} />
    </Route>
  </Routes>
);
