import { Loader } from "components/Loader";
import { NovelCard } from "components/NovelCard";
import { useAuth } from "hooks";
import i18next, { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddToWish } from "shared";

export const Wishlist = () => {
  const { bearerToken } = useAuth();
  const currentLang = i18next.language;
  const navigate = useNavigate()
  const [novels, setNovels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorFetching , setErrorFetching] = useState('');

  const handleWishClick = (token, id) => {
    AddToWish(token, id);
    const updatedNovels = novels.filter((novel) => novel.id !== id);
    setNovels(updatedNovels);
  };
  const fetchContent = useCallback(() => {
    const url = `${process.env.REACT_APP_BASE_URL}/get-wishlist`;
    const headers = {
      authorization: `Bearer ${bearerToken}`,
      lang:currentLang,
    };
    const options = {
      headers: headers,
    };

    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          const fetchedNovels = data.data;
          setNovels(fetchedNovels);
          setIsLoading(false);
          setErrorFetching('');
        } else {
          throw data.message;
        }
      })
      .catch((error) => {
        // setErrorFetching(t('Somthing went wrong please check your internet connection or try again later'));
        console.error(error);
      });
  }, [bearerToken,currentLang]);

  useEffect(() => {
    setIsLoading(true);
    fetchContent();
  }, [fetchContent]);
  const handleNovels = () =>{
    navigate('/dashboard/novels')
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="wishlist">
          {errorFetching && <p style={{color:"red" , background:"white" , borderRadius:"0.5rem" , padding:"1rem"}}>{errorFetching}</p>}
          {novels.length > 0 ? novels.map((card, key) => (
            <NovelCard
              card={card}
              key={key}
              token={bearerToken}
              handleWishClick={()=>{handleWishClick(bearerToken,card.id)}}
            />
          ))
          :
          <>
      
<svg width="300px" height="500px" viewBox="0 0 837 1045" version="1.1" >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" >
        <path d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z" id="Polygon-1" stroke="#007FB2" stroke-width="6" ></path>
        <path d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z" id="Polygon-2" stroke="#EF4A5B" stroke-width="6" ></path>
        <path d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z" id="Polygon-3" stroke="#795D9C" stroke-width="6" ></path>
        <path d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z" id="Polygon-4" stroke="#F2773F" stroke-width="6" ></path>
        <path d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z" id="Polygon-5" stroke="#36B455" stroke-width="6" ></path>
    </g>
</svg>
<div class="message-box">
  <h1>{t('No Novels Found')}</h1>
  <div class="buttons-con">
    <div class="action-link-wrap">
      <button onClick={handleNovels} class="link-button link-back-button">{t('Show Novels')}</button>
    </div>
  </div>
</div>

</>
}
        </div>
      )}
    </>
  );
};
